<template>
  <div class="col-md-12 mb-10">
    <div class="row">
      <div class="col-md-3">
        <div class="form-group">
          <label for="headerColor">Top Header Color:</label>
          <input
            type="color"
            class="form-control"
            id="headerColor"
            v-model="templateData.pages.home.headerColor"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="bannerColor"> Advertisment Background:</label>
          <input
            type="color"
            class="form-control"
            id="bannerColor"
            v-model="templateData.pages.home.bannerColor"
          />
        </div>
      </div>
      <div class="col-md-3">
        <div class="form-group">
          <label for="footerColor">Website Footer Color:</label>
          <input
            type="color"
            class="form-control"
            id="footerColor"
            v-model="templateData.pages.home.footerColor"
          />
        </div>
      </div>

      <div class="col-md-3">
        <div class="form-group">
          <label for="menuFontSize">Food Categories Menu Font Size:</label>
          <input
            type="number"
            class="form-control"
            id="menuFontSize"
            v-model="templateData.pages.home.menu_font_size"
          />
        </div>
      </div>
    </div>
    <!-- Header Color Picker -->

    <!-- Banner Color Picker -->

    <b-tabs v-if="templateData != null" content-class="mt-3">
      <b-tab
        v-for="(section, sectionName) in templateData.pages.home.sections"
        :key="sectionName"
        :title="firstLetterUppercase(sectionName)"
        >
        <div class="row">
          <div class="col-md-6">
            <div v-for="(item, itemName) in section" :key="itemName">
          <div v-if="sectionName == 'banner'" v-for="(slide, slideName) in item">


            <div v-if="slide.type === 'image'">
              <div class="row form-group">
                <div class="col-md-12">
                  <label>{{ slide.label }}</label>
                </div>
                <div class="col-md-7">
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    name="banner"
                    v-model="slide.value"
                    @input="uploadImage(sectionName, itemName)"
                  ></b-form-file>
                  <img  v-if="slide.path"  style="width: 400px; height: 200px" :src="baseUrl+slide.path" />
                </div>
              </div>
            </div>
            <div v-else-if="slide.type === 'text'">
              <!-- Render text -->
              <label>{{ slide.label }}</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :placeholder="slide.default"
                v-model="slide.value"
              />
            </div>
          </div>
          <div v-if="sectionName != 'banner'">
            <div v-if="item.type === 'image'">
              <div class="row form-group">
                <div class="col-md-12">
                  <label>{{ item.label }}</label>
                </div>
                <div class="col-md-7">
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    name="banner"
                    v-model="item.value"
                    @input="uploadImage(sectionName, itemName)"
                  ></b-form-file> 
                  <img v-if="item.path " style="width: 350px; height: 200px" class="m-2"  :src="baseUrl+item.path" />
                </div>
              </div>
            </div>
            <div v-else-if="item.type === 'text'">
              <!-- Render text -->
              <label>{{ item.label }}</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :placeholder="item.default"
                v-model="item.value"
              />
            </div>
          </div>
        </div>
            </div>
        </div>
    
        <!-- <button class="btn btn-primary mt-3 mr-4" @click="saveTemplate(sectionName)">Save</button> -->

        <v-btn  large class="mt-3 btn-primary text-light" @click="saveTemplate(sectionName)">
  Save 
</v-btn>
<v-btn  large class="mt-3 ml-2 text-light  "  style="background-color: red;" @click="resetTheme()">
  Reset To Default
</v-btn>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { GET_CURRENT_TEMPLATE } from "@/core/services/store/profile.module";
import Swal from "sweetalert2";

import {
  SAVE_TEMPLATE,
  UPLOAD_TEMPLATE_IMAGES
} from "@/core/services/store/profile.module";
import { mapGetters } from "vuex";

export default {
  props: {
    template: Number
  },
  name: "TemplateSetting",
  computed: {
    ...mapGetters(["currentUser", "currentUserPersonalInfo"])
  },
  data() {
    return {
      temp: null,
      templates: [],
      templateData: null,
      selectedFile: null,
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    firstLetterUppercase(title) {
  if (!title) return ''; // Handle empty string

  return title.charAt(0).toUpperCase() + title.slice(1);
},
    makeToast(variant, message) {
      this.$bvToast.toast(message, {
        title: "Message",
        variant: variant,
        solid: true
      });
    },
  async resetTheme() {
  const { value: confirmed } = await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, reset it!"
  });

  if (confirmed) {
    try {
      const module = await import(
        `/src/core/config/templates/template${this.currentUserPersonalInfo.template_id}/${this.currentUserPersonalInfo.food_type_name}/template.json`
      );
      
      this.templateData = module.default;
      console.log('this.templateData.pages.home.menu_font_size', this.templateData.pages.home.menu_font_size);

      this.templateData.pages.home.headerColor = this.templateData.pages.home.headerColor || "#0c0303";
      this.templateData.pages.home.bannerColor = this.templateData.pages.home.bannerColor || "#e70d5a";
      this.templateData.pages.home.footerColor = this.templateData.pages.home.footerColor || "#FFFFFF";
      this.templateData.pages.home.menu_font_size =
        this.templateData.pages.home.menu_font_size || 16; // Default font size is set to 16

      await this.saveTemplate();
    } catch (error) {
      console.error(
        'Error importing template or saving template:',
        error
      );
    }
  }
},
    saveTemplate(sectionName) {
      if (this.currentUser != "") {
        console.log("this.template", this.templateData);
        const form = {
          templateData: this.templateData,
          business: this.currentUser.id,
          template: this.template
        };

        this.$store.dispatch(SAVE_TEMPLATE, form).then(data => {
          this.templateData = data.data;
          this.makeToast("success", "Business Template updated successfully");
        });
      }
    },
    handleFileChange(event) {
      console.log(event.target.files[0]);
      this.templateData["pages"]["home"]["sections"]["banner"]["slide_1"][
        "image"
      ]["value"] = event.target.files[0];
    },
    uploadImage(sectionName, itemName) {
      if (sectionName == "banner") {
        this.selectedFile = this.templateData.pages.home.sections[sectionName][
          itemName
        ].image.value;
      } else {
        this.selectedFile = this.templateData.pages.home.sections[sectionName][
          itemName
        ].value;
      }

      const formData = new FormData();
      formData.append("image", this.selectedFile);
      formData.append("image_key", itemName);
      formData.append("image_section", sectionName);
      formData.append("template_id", this.template);
      formData.append("user_id", this.currentUser.id);

      this.$store.dispatch(UPLOAD_TEMPLATE_IMAGES, formData).then(data => {
        if (sectionName == "banner") {
          this.templateData.pages.home.sections[sectionName][
            itemName
          ].image.path = data;
        } else {
          this.templateData.pages.home.sections[sectionName][
            itemName
          ].path = data;
        }
      });
    },
    loadTemplateData(template_id) {
      debugger
      console.log("loadTemplateData", template_id);
       console.log('this.templateData.',this.templateData)
      this.$store
        .dispatch(GET_CURRENT_TEMPLATE, { template_id: template_id })
        .then(result => {
          
          if (result.data.template_data) {
            this.templateData = result.data.template_data;
            this.templateData.pages.home.headerColor =
              result.data.template_data.pages.home.headerColor || "#0c0303";
            this.templateData.pages.home.bannerColor =
              result.data.template_data.pages.home.bannerColor || "#e70d5a";
            this.templateData.pages.home.footerColor =
              result.data.template_data.pages.home.footerColor || "#FFFFFF";


            this.templateData.pages.home.menu_font_size =
              result.data.template_data.pages.home.menu_font_size || 16; // Default font size is set to 16
          } else {
            if (template_id == 1) {
              import(
                "/src/core/config/templates/template" +
                  template_id +
                  "/" +
                  this.currentUserPersonalInfo.food_type_name +
                  "/template.json"
              )
                .then(module => {
                  this.templateData = module.default;
                  console.log('this.templateData.pages.home',this.templateData.pages.home.headerColor)
             
                  this.templateData.pages.home.headerColor = this.templateData.pages.home.headerColor || "#0c0303";
                 this.templateData.pages.home.bannerColor = this.templateData.pages.home.bannerColor || "#e70d5a";
                  this.templateData.pages.home.footerColor = this.templateData.pages.home.footerColor || "#FFFFFF";
                  this.templateData.pages.home.menu_font_size =
                    this.templateData.pages.home.menu_font_size || 16; // Default font size is set to 16
                })
                .catch(error => {
                  console.log(
                    `module.default`,
                    `src/core/config/templates/template` +
                      template_id +
                      `/`+`template.json`
                  );
                
                });
            } else {
              console.log('food_type_name', this.currentUserPersonalInfo.food_type_name)
              console.log("Error 999:", `src/core/config/templates/template` +
                      template_id +
                      `/`+`template.json`);
                      import(
                "/src/core/config/templates/template" +
                  template_id +
                  "/" +
                  this.currentUserPersonalInfo.food_type_name +
                  "/template.json"
              )
                .then(module => {
                  this.templateData = module.default;
                  this.templateData.pages.home.menu_font_size =
                    this.templateData.pages.home.menu_font_size || 16; // Default font size is set to 16
                })
                .catch(error => {
                  console.error("Error loading JSON file:", error);
                });
            }
          }
        });
    }
  },
  mounted() {
    this.loadTemplateData(this.template);
  },
  watch: {
    template(newVal, oldVal) {
      this.loadTemplateData(newVal);
    }
  }
};
</script>
